import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {SectionHeading, Subheading as SubheadingBase} from "components/misc/Headings.js";
import {PrimaryButton as PrimaryButtonBase} from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-12 md:py-16`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const InputError = tw.div`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500 text-red-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
    ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

const FormResult = tw.div`my-8 max-w-sm text-green-700 text-center`
const ResultSuccess = tw.div`text-green-700`
const ResultLoading = tw.div`text-primary-500`
const ResultError = tw.div`text-red-500`

export default ({
                  subheading = "Связаться с нами",
                  heading = <>Не стесняйтесь <span tw="text-primary-500">связаться</span>
                    <wbr/>
                    &nbsp;с нами.</>,
                  description = "Если у вас есть вопросы или предложения, не стесняйтесь связаться с нами, заполнив эту форму. Мы постараемся ответить вам как можно скорее.",
                  submitButtonText = "Отправить",
                  formAction = "contact",
                  formMethod = "POST",
                  textOnLeft = true,
                }) => {
  const [csrfToken, setCsrfToken] = useState('');
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await fetch(`${apiUrl}/csrf`, {
          method: 'GET',
          credentials: 'include', // Include cookies for cross-origin requests
          headers: {
            'Accept': 'application/json',
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setCsrfToken(data.token)
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    fetchCsrfToken();
  }, [apiUrl]);

  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    name: '',
    message: '',
  });

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const normalize = (formData) => {
    const normalizedData = {};
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        normalizedData[key] = typeof formData[key] === 'string' ? formData[key].trim() : formData[key];
      }
    }
    return normalizedData;
  };

  const validate = (formData) => {
    const errors = {};
    if (formData.email.length !== 0 && (formData.email.length < 2 || formData.email.length > 100)) {
      errors.email = 'Эл. почта должна содержать от 2 до 100 символов';
    }

    if (formData.phone.length !== 0 && (formData.phone.length < 5 || formData.phone.length > 100)) {
      errors.phone = 'Телефон должен содержать от 5 до 100 символов';
    }

    if (formData.email.length === 0 && formData.phone.length === 0) {
      errors.general = 'Укажите телефон или эл. почту';
    }

    if (!formData.name || formData.name.length < 2 || formData.name.length > 100) {
      errors.name = 'Имя обязательно и должно содержать от 2 до 100 символов';
    }

    if (!formData.message || formData.message.length < 2 || formData.message.length > 2000) {
      errors.message = 'Сообщение обязательно и должно содержать от 2 до 2000 символов';
    }

    return errors
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    setSuccessMessage('')

    let normalized = normalize(formData);
    let errors = validate(normalized);
    setErrors(errors)

    if (Object.keys(errors).length !== 0) {
      setIsSubmitting(false);

      return;
    }

    try {
      const payload = {
        ...normalized,
        _token: csrfToken, // Include the CSRF token in the payload
      };

      const response = await fetch(apiUrl + '/' + formAction, {
        method: formMethod || 'POST',
        credentials: 'include', // Include cookies for cross-origin requests
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setSuccessMessage('Form submitted successfully!');
        setFormData({
          email: '',
          phone: '',
          name: '',
          message: ''
        });

        setIsSubmitting(false);
        setSuccessMessage('Сообщение отправлено!')
      } else {
        setErrors({general: 'Ошибка отправки формы'})
        setIsSubmitting(false);
      }
    } catch (error) {
      setErrors({general: 'Ошибка отправки формы'})
      setIsSubmitting(false);
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errors, setErrors] = useState({});


  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc}/>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={handleSubmit} method={'POST'}>
              <Input type="email" name="email" placeholder="Электронная почта" onChange={handleChange}
                     value={formData.email}/>
              {errors.email && <InputError>{errors.email}</InputError>}

              <Input type="text" name="phone" placeholder="Телефон" onChange={handleChange} value={formData.phone}/>
              {errors.phone && <InputError>{errors.phone}</InputError>}

              <Input type="text" name="name" placeholder="Имя" onChange={handleChange} value={formData.name}/>
              {errors.name && <InputError>{errors.name}</InputError>}

              <Textarea name="message" placeholder="Сообщение" onChange={handleChange} value={formData.message}/>
              {errors.message && <InputError>{errors.message}</InputError>}

              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
            <FormResult>
              <ResultLoading>
                {isSubmitting && <div>Отправляем...</div>}
              </ResultLoading>
              <ResultSuccess>
                {successMessage && <div>{successMessage}</div>}
              </ResultSuccess>
              <ResultError>
                {errors.general && <div>{errors.general}</div>}
              </ResultError>
            </FormResult>

          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
