import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import Gallery from "components/gallery/SimpleGallery";
import SimpleWithSideImage from "../components/faqs/SimpleWithSideImage";


export default () => {
  return (
    <AnimationRevealPage>
      <Header/>
      <Gallery/>
      <SimpleWithSideImage/>
      <Footer/>
    </AnimationRevealPage>
  );
};
