import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ContactDetails.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Header/>
      <ContactUsForm/>
      <ContactDetails/>
      <Footer/>
    </AnimationRevealPage>
  );
};
