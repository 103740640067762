import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import ImageSrc from "images/stairs/faq/faq.jpg";
import {PrimaryLink as PrimaryLinkBase} from "../misc/Links";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

const LinkContainer = tw.div`text-center`
const PrimaryLink = styled(PrimaryLinkBase)`
    ${tw`inline-flex justify-center xl:justify-start items-center mt-2 text-lg text-secondary-100`}
    svg {
        ${tw`ml-2 w-5 h-5`}
    }
`;

export default ({
  subheading = "",
  heading = "Вопросы",
  description = "Если вы не нашли ответ на свой вопрос, просто оставьте заявку на сайте или позвоните нам по телефону +7 926 084-95-04 и мы бесплатно проконсультируем вас по выбору лестницы и ответим на ваши вопросы.",
  imageSrc = ImageSrc,
  imageContain = false,
  imageShadow = true,
  faqs = null
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "Какие материалы доступны для изготовления лестницы?",
      answer:
        "Мы используем дуб, бук, ясень, лиственницу, берёзу или шпонированный материал, на Ваш выбор. Самые надежные и красивые варианты для ступеней лестницы это дуб, ясень и шпонированные."
    },
    {
      question: "Делаете ли вы подсветку, кладовку, подоконники?",
      answer:
        "Да. Их можно сделать как вместе с лестницей так и отдельными этапами."
    },
    {
      question: "Какая гарантия? Есть ли отзывы?",
      answer:
        "Гарантия 2 года на отсутствие скрипов, щелей и деформаций. Используем надежные материалы и технологии, чтобы гарантийных случаев было меньше. Отзывы можно посмотреть в нашем Instagram."
    },
    {
      question: "Есть ли у Вас свои дизайнеры?",
      answer:
        "Да, мы сотрудничаем с частными дизайнерами, архитекторами и с бюро. Поможем сделать как стандартные задачи, так и индивидуальные решения. Можем дать рекомендации других дизайнеров/архитекторов."
    },
    {
      question: "Можете подобрать цвет ступеней под цвет пола/дверей/подоконников?",
      answer:
        "Да, мы можем взять у вас образец и сделать по нему подбор цвета ступеней лестницы. Цвет ступеней не зависит от вида дерева."
    },
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <Content>
        <TwoColumn>
          <Column tw="hidden lg:block w-5/12 flex-shrink-0">
            <Image imageContain={imageContain} imageShadow={imageShadow} imageSrc={imageSrc} />
          </Column>
          <Column>
            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <LinkContainer>
              <PrimaryLink href="/contact">
                Написать нам <ArrowRightIcon />
              </PrimaryLink>
              </LinkContainer>
              <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: {opacity: 1, height: "auto", marginTop: "16px"},
                        collapsed: {opacity: 0, height: 0, marginTop: "0px"}
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98]}}
                    >
                      {faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
