import React from "react";
import {ReactComponent as SvgDecoratorBlob} from "images/svg-decorator-blob-3.svg";
import styled from "styled-components";
import tw from "twin.macro";

const DecoratorBlob = styled(SvgDecoratorBlob)`
    ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const DecoratorBlob1 = () => {

  return (
    <DecoratorBlob/>
  );
}

export default DecoratorBlob1;