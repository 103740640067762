import React from 'react';

import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import './style.css'
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import {Icon} from 'leaflet'

import tw from "twin.macro";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-lg mx-auto pb-12 lg:pb-16`;

function Map() {
  //https://www.openstreetmap.org/?mlat=#map=8/55.970590/37.743777
  const position = [55.970590, 37.743777]

  return (
    <Container>
      <SingleColumn>
        <MapContainer center={position} zoom={16} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position} icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}>
            <Popup>
              A pretty CSS3 popup. <br/> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer>
      </SingleColumn>
    </Container>
  );
}

export default Map;