import React from 'react';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/VerticalWithAlternateImageAndText.js";
import ThreeColWithSideImage from "components/features/ThreeColWithSideImage";
import Footer from "components/footers/SimpleFiveColumn.js";
import TwoColContactUsWithIllustrationFullForm from "../components/forms/TwoColContactUsWithIllustrationFullForm";
import PortfolioButton from "../components/button/PortfolioButton";
import SimpleWithSideImage from "../components/faqs/SimpleWithSideImage";

export default () => (
  <AnimationRevealPage>
    <Hero/>
    <Features/>
    <ThreeColWithSideImage/>
    <SimpleWithSideImage/>
    <TwoColContactUsWithIllustrationFullForm/>
    <PortfolioButton/>
    <Footer/>
  </AnimationRevealPage>
);
