import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import {SectionHeading} from "components/misc/Headings.js";

const Container = tw.div`relative`;
const HeadingSmall = tw(SectionHeading)`w-full font-bold text-3xl`;

const VerticalSpacer = tw.div`mt-10 w-full`


const RowColumn = styled.div`
    ${tw`w-full text-center`}
`;

const TwoColumn = tw.div`flex flex-col md:flex-row md:items-center max-w-screen-xl mx-auto md:py-1`;
const LeftColumn = tw.div`relative mt-6 md:mt-0 md:w-6/12 md:pr-12 flex-shrink-0 text-left md:text-right text-primary-700 font-bold`;
const RightColumn = tw.div`relative mt-2 md:mt-0 flex flex-col text-left text-primary-500`;


export default () => {
  return (
    <Container>
        <HeadingSmall>Реквизиты</HeadingSmall>
        <VerticalSpacer/>
        <RowColumn>
          <TwoColumn>
            <LeftColumn>Компания</LeftColumn>
            <RightColumn>Индивидуальный предприниматель Попова Светлана Андреевна</RightColumn>
          </TwoColumn>
        </RowColumn>
        <RowColumn>
          <TwoColumn>
            <LeftColumn>Юридический адрес</LeftColumn>
            <RightColumn>141033, РОССИЯ, обл МОСКОВСКАЯ, г МЫТИЩИ, п МЕБЕЛЬНОЙ ФАБРИКИ, ул РАССВЕТНАЯ, ДОМ 1А, кв 106</RightColumn>
          </TwoColumn>
        </RowColumn>
        <RowColumn>
          <TwoColumn>
            <LeftColumn>ИНН</LeftColumn>
            <RightColumn>253717175663</RightColumn>
          </TwoColumn>
        </RowColumn>
        <RowColumn>
          <TwoColumn>
            <LeftColumn>ОГРНИП</LeftColumn>
            <RightColumn>321508100160141</RightColumn>
          </TwoColumn>
        </RowColumn>
        <RowColumn>
          <TwoColumn>
            <LeftColumn>Расчётный счёт</LeftColumn>
            <RightColumn>40802810201500160054</RightColumn>
          </TwoColumn>
        </RowColumn>
        <RowColumn>
          <TwoColumn>
            <LeftColumn>Наименование банка</LeftColumn>
            <RightColumn>ООО "Банк Точка"</RightColumn>
          </TwoColumn>
        </RowColumn>
        <RowColumn>
          <TwoColumn>
            <LeftColumn>ИНН банка</LeftColumn>
            <RightColumn>9721194461</RightColumn>
          </TwoColumn>
        </RowColumn>
        <RowColumn>
          <TwoColumn>
            <LeftColumn>БИК банка</LeftColumn>
            <RightColumn>044525104</RightColumn>
          </TwoColumn>
        </RowColumn>
        <RowColumn>
          <TwoColumn>
            <LeftColumn>Корреспондентский счёт</LeftColumn>
            <RightColumn>30101810745374525104</RightColumn>
          </TwoColumn>
        </RowColumn>
        <RowColumn>
          <TwoColumn>
            <LeftColumn>Юридический адрес банка</LeftColumn>
            <RightColumn>109044, Российская Федерация, г. Москва, вн. тер. г. муниципальный округ Южнопортовый, пер. 3-й Крутицкий, д.11, помещ. 7Н</RightColumn>
          </TwoColumn>
        </RowColumn>
    </Container>
  );
};
