import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

import feat1 from "../../images/stairs/features/1-1.jpg"
import feat2 from "../../images/stairs/features/2-1.jpg"
import feat3 from "../../images/stairs/features/3-1.png"
import feat4 from "../../images/stairs/air/1.jpeg"

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-md`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/2 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
        `${feat4}`,
      subtitle: "Современный дизайн",
      title: "Воздушные лестницы",
      description:
        "Проектируем по индивидуальному заказу с учетом ваших предпочтений и возможностей помещения",
      url: "/portfolio"
    },
    {
      imageSrc:
        `${feat3}`,
      subtitle: "Индивидуально",
      title: "Лестницы из дерева",
      description:
        "От простых шаблонных решений до индивидуальных",
      url: "/portfolio"
    },
    {
      imageSrc:
        `${feat1}`,
      subtitle: "Отделка",
      title: "Отделка бетонных лестниц",
      description:
        "Предлагаем широкий спектр услуг и материалов для создания красивой и функциональной лестницы",
      url: "/portfolio"
    },
    {
      imageSrc:
        `${feat2}`,
      subtitle: "Облицовка",
      title: "Облицовка на металлокаркасе",
      description:
        "Облицовка лестниц на металлическом каркасе любых форм и конфигураций, открытого и закрытого типов",
      url: "/portfolio"
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Изготавливаем и монтируем лестницы и перила из дерева под ключ</HeadingTitle>
          <HeadingDescription>
            Гарантия на материалы и работы: если в процессе эксплуатации выявится дефект исправим за свой счёт
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <Link href={card.url}>Примеры</Link>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
