import React from "react";
import styled from "styled-components";
import tw from "twin.macro";


import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";

import {PrimaryLink as PrimaryLinkBase} from "components/misc/Links.js";

const Container = tw.div`relative text-center pb-24`;

const PrimaryLink = styled(PrimaryLinkBase)`
    ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
    svg {
        ${tw`ml-2 w-5 h-5`}
    }
`;

export default () => {
  return (
    <Container>
      <PrimaryLink href="/portfolio">
        Посмотреть портфолио <ArrowRightIcon />
      </PrimaryLink>
    </Container>
  );
};
