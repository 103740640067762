import React from "react";
import tw from 'twin.macro';
import ImageGallery from "react-image-gallery";
import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import {SectionHeading as HeadingTitle} from "../misc/Headings";
import "./style.css"

import DecoratorBlob1 from "../blob/DecoratorBlob";

// Import all images
const srcImages = [
  require("../../images/stairs/gallery/2-1.jpg"),
  require("../../images/stairs/gallery/2-2.jpg"),
  require("../../images/stairs/gallery/2-3.jpg"),
  require("../../images/stairs/gallery/2-5.jpg"),
  require("../../images/stairs/gallery/3-50.png"),
  require("../../images/stairs/gallery/3-10.png"),
  require("../../images/stairs/gallery/3-20.jpg"),
  require("../../images/stairs/gallery/3-30.jpg"),
  require("../../images/stairs/gallery/3-30.png"),
  require("../../images/stairs/gallery/3-40.jpg"),
  require("../../images/stairs/gallery/3-90.jpg"),
  require("../../images/stairs/gallery/3-50.jpg"),
  require("../../images/stairs/gallery/3-60.jpg"),
  require("../../images/stairs/gallery/3-80.jpg"),
  require("../../images/stairs/gallery/3-10.jpg"),
];

const Container = tw.div`relative`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-md`;

const Content = tw.div`mt-16 relative`;

const SingleColumn = tw.div`max-w-screen-lg mx-auto py-20 lg:py-24`;

const GalleryComponent = () => {
  const images = srcImages.map(image => ({
    original: image,
    thumbnail: image,
  }));

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>
            Примеры наших работ
          </HeadingTitle>
          <HeadingDescription>
            Все предтавленные фотографии из уже реализовнных проектов нами.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          <ImageGallery items={images}/>
        </Content>
      </SingleColumn>
      <DecoratorBlob1/>
    </Container>
  );
}

export default GalleryComponent;